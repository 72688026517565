<template>
  <div class="containers">
    <div class="container">
      <label for="markdown">编辑</label>
      <textarea id="markdown" class="inputPane" v-model="markdown"></textarea>
    </div>
    <div class="container">
      <button @click="save">发布</button>
      <span id="html" class="pane" v-html="html"></span>
    </div>
  </div>
</template>

<script>
const marked = require('marked');

export default {
  name: 'Edit',
  data: () => ({
    articleID: 0,
    markdown: '# Hi，朋友\n\nRendered by **marked**.',
    html: '',
  }),
  created() {
    // 获取博文并转为 html
    this.articleID = this.$route.params.id - 0;
    if (typeof (this.articleID) === 'number' && !Number.isNaN(this.articleID)) {
      this.$http.get(`/api/blog/article?id=${this.articleID}`).then((response) => {
        if (response.data.article === null) {
          return this.$tip.fire({
            title: '文章不存在',
          }).then(() => {
            this.$router.push('/blog');
          });
        }

        this.markdown = response.data.article;
        return 1;
      });
    } else {
      this.html = marked(this.markdown);
    }
  },
  methods: {
    save() {
      console.info('need save.');
    },
  },
  watch: {
    markdown() {
      this.html = marked(this.markdown);
    },
  },
};
</script>

<style scoped>
textarea {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 12px;
  resize: none;
}

.containers {
  display: flex;
  height: calc(100vh - 68px);
}

.container {
  flex-basis: 50%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.pane, .inputPane {
  margin-top: 5px;
  padding: 0.6em;
  border: 1px solid #ccc;
  overflow: auto;
  flex-grow: 1;
  flex-shrink: 1;
}
</style>
